import * as React from 'react';
import { filter, concat, set } from 'lodash';
import { createApplication, WorkstreamTrafficControl, DEFAULT_THEME, entityConfigs, appSectionConfigs as coreAppSectionConfigs, AppSectionDefinitionSet, AppSectionSet, Icons } from '@shapeable/core';
import { CreditLogo } from './components';

const theme = DEFAULT_THEME;

// change appSections

const appSectionConfigs: AppSectionSet = coreAppSectionConfigs;

appSectionConfigs.platform.showInNavigation = false;
appSectionConfigs.economies.showInNavigation = false;

appSectionConfigs.impactStories.label = 'Solutions';
appSectionConfigs.impactStories.path = 'solutions';

appSectionConfigs.topicTeams.label = 'Working Groups';
appSectionConfigs.topicTeams.path = 'working-groups';

// pre-patch configs to ensure that derived properties are correctly created 

entityConfigs.Location.options = { statistics: 'basic' };

entityConfigs.ImpactStory.label = 'Solution';
entityConfigs.ImpactStory.slug = 'solution';

entityConfigs.MaturityStage.label = 'Pattern Type';
entityConfigs.MaturityStage.slug = 'pattern-type';

entityConfigs.ListImpactStory.label = 'Collection Solution';
entityConfigs.ListImpactStory.slug = 'collection-solution';

entityConfigs.TopicTeam.slug = 'working-group';
entityConfigs.TopicTeam.pluralSlug = 'working-groups';
entityConfigs.TopicTeam.label = 'Working Group';
entityConfigs.TopicTeam.pluralLabel = 'Working Groups';

set(entityConfigs.TopicTeam, 'list.banner.title', entityConfigs.TopicTeam.pluralLabel);

export const application = createApplication({
  theme,
  entityConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'food',
  },
  appSectionConfigs: appSectionConfigs as AppSectionSet,
  components: {
    CreditLogo,
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
entities.TopicTeam.list.postBanner = <WorkstreamTrafficControl />;
